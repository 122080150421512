





















import { environment } from '@/environments/environment';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Navbar from '@/components/Navbar.vue';

const Auth = namespace('Auth');

@Component({
  components: {
    Navbar,
  },
})
class App extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean;

  menuVisible = false;
  env = environment;

  linkClickEmitted(menuVisible: boolean) {
    this.menuVisible = menuVisible;
  }
}
export default App;
