
























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Auth = namespace('Auth');

@Component
export default class Navbar extends Vue {
  @Auth.Action
  private signOut!: () => void;

  @Auth.Getter
  private isLoggedIn!: boolean;

  goTo(path: string) {
    this.$emit('linkClickEmitted', false);
    this.$router.push(path);
  }

  isActive(path: string) {
    return path === this.$route.path;
  }

  performLogout() {
    this.$emit('linkClickEmitted', false);
    this.signOut();
    this.$router.push('/login');
  }
}
