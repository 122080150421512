import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/services/Auth/AuthService';
import { User as ModelUser } from '@/interfaces/models/User';
import { DTO } from '@/interfaces/DTO';

const storedUser = localStorage.getItem('user');

@Module({ namespaced: true })
class User extends VuexModule {
  status = storedUser ? { loggedIn: true } : { loggedIn: false };
  user = storedUser ? JSON.parse(storedUser) : null;

  @Mutation
  loginSuccess(user: ModelUser): void {
    this.status.loggedIn = true;
    this.user = user;
  }

  @Mutation
  loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  logout(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Action({ rawError: true })
  login(data: DTO.login.Request): Promise<DTO.login.Response> {
    return AuthService.login(data).then(
      (user) => {
        this.context.commit('loginSuccess', user);
        return Promise.resolve(user);
      },
      (error) => {
        this.context.commit('loginFailure');
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject(message);
      },
    );
  }

  @Action
  signOut(): void {
    AuthService.logout();
    this.context.commit('logout');
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
}

export default User;
