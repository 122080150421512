import axios from 'axios';
import { environment } from '@/environments/environment';
import { DTO } from '@/interfaces/DTO';

class AuthService {
  login(data: DTO.login.Request) {
    return axios
      .post(environment.baseUrl + '/login', {
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        if (response.status === 200 && response.data && response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
